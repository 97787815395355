<template>
  <div class="legal-wrapper">
      <h3>{{ $t('GENERAL.faqs_full') }}</h3>
      <br><br>
      <div>
        <div class="faq" v-for="faq in translatedFaqs" v-bind:key="faq.title">
          <h4>{{ faq.title }}</h4>
          <p>{{ faq.content }}</p>
        </div>
      </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import i18nmixin from '@/services/mixins/i18n.mixins'

export default {
  name: 'FaqPage',
  mixins: [i18nmixin],
  methods: {
    ...mapActions({
      getFaqs: 'staticInfo/getFaqs'
    })
  },
  computed: {
    ...mapGetters({
      faqs: 'staticInfo/getFaqs'
    }),
    translatedFaqs () {
      return this.translateObjectArray(this.faqs)
    }
  },
  mounted () {
    this.getFaqs()
  }
}
</script>

<style lang="scss" scoped>
.legal-wrapper {
  padding: 2rem 0;
  width: 70%;
  margin: 0 auto;
}

.faq:not(:first-child) {
  margin-top: 2rem;
}
</style>
